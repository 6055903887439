/* Core CSS required for Ionic components to work properly */
@use './theme/shared-var.scss';
@import '@ionic/angular/css/core.css';

/* Basic CSS for apps built with Ionic */
@import '@ionic/angular/css/normalize.css';
@import '@ionic/angular/css/structure.css';
@import '@ionic/angular/css/typography.css';
@import '@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import '@ionic/angular/css/padding.css';
@import '@ionic/angular/css/float-elements.css';
@import '@ionic/angular/css/text-alignment.css';
@import '@ionic/angular/css/text-transformation.css';
@import '@ionic/angular/css/flex-utils.css';

@import '@angular/cdk/overlay-prebuilt.css';
@import '@angular/cdk/a11y-prebuilt.css';
@import '@angular/cdk/text-field-prebuilt.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  /* Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}

/* You can add global styles to this file, and also import other style files */
:root {
  --ion-color-primary: #f68a22;
  --ion-color-primary-secondary: #f9af58;
  --ion-color-primary-tertiary: #ffe6c1;
  --ion-color-primary-rgb: 246, 138, 34;
  --ion-color-primary-hsl: 32deg 93% 66%;
  --ion-color-primary-hs: 32deg 93%;
  --ion-color-primary-contrast: var(--ion-color-light);
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #db9a4d;
  --ion-color-primary-tint: #fab769;

  --ion-color-secondary: #3dc2ff;
  --ion-color-secondary-rgb: 61, 194, 255;
  --ion-color-secondary-contrast: var(--ion-color-light);
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #36abe0;
  --ion-color-secondary-tint: #50c8ff;

  --ion-color-tertiary: #1a73e8;
  --ion-color-tertiary-rgb: 26, 115, 232;
  --ion-color-tertiary-contrast: var(--ion-color-light);
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #4854e0;
  --ion-color-tertiary-tint: #6370ff;

  --ion-color-success: #2bb34d;
  --ion-color-success-secondary: #5ad16c;
  --ion-color-success-tertiary: #7ee884;
  --ion-color-success-rgb: 43, 179, 77;
  --ion-color-success-contrast: var(--ion-color-light);
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #4fb85f;
  --ion-color-success-tint: #6bd67b;

  --ion-color-warning: #ffab00;
  --ion-color-warning-secondary: #ffc63f;
  --ion-color-warning-tertiary: #fff0c8;
  --ion-color-warning-rgb: 255, 171, 0;
  --ion-color-warning-contrast: var(--ion-color-light);
  --ion-color-warning-contrast-rgb: 255, 255, 255;
  --ion-color-warning-shade: #e0ae37;
  --ion-color-warning-tint: #ffcc52;

  --ion-color-info: #1a73e8;
  --ion-color-info-secondary: #ffc63f;
  --ion-color-info-tertiary: #fff0c8;
  --ion-color-info-rgb: 26, 115, 232;
  --ion-color-info-contrast: var(--ion-color-light);
  --ion-color-info-contrast-rgb: 255, 255, 255;
  --ion-color-info-shade: #e0ae37;
  --ion-color-info-tint: #ffcc52;

  --ion-color-danger: #f0624d;
  --ion-color-danger-secondary: #f69278;
  --ion-color-danger-tertiary: #ffded0;
  --ion-color-danger-rgb: 240, 98, 77;
  --ion-color-danger-contrast: var(--ion-color-light);
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #d8806a;
  --ion-color-danger-tint: #f79d86;

  --ion-color-dark: #363636; // sementara
  --ion-color-dark-rgb: 54, 54, 54; // sementara
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  --ion-color-medium: #898989; // sementara
  --ion-color-medium-rgb: 137, 137, 137; // sementara
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  --ion-color-light: #f8f8f8; // sementara
  --ion-color-light-rgb: 248, 248, 248; // sementara
  --ion-color-light-secondary: #e9ebee;
  --ion-color-light-tertiary: #f0f2f4;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;
  --ion-color-light-mode: #d7dae0;

  --ion-color-base: var(--ion-color-primary);
  --ion-color-base-rgb: var(--ion-color-primary-rgb);
  --ion-color-contrast: var(--ion-color-primary-contrast);
  --ion-color-contrast-rgb: var(--ion-color-primary-contrast-rgb);
  --ion-color-shade: var(--ion-color-primary-shade);
  --ion-color-tint: var(--ion-color-primary-tint);
}

body {
  // background: rgb(229, 231, 235);
}

.mobile-label {
  display: none;
}

ion-label {
  display: block;
}

ion-title {
  font-weight: bold;
}

ion-item {
  --background: unset !important;
  background: unset !important;
}

@media screen and (max-width: 960px) {
  .mobile-label {
    @apply right-0 inline-block font-bold;
  }
}

* {
  scroll-behavior: smooth;
}

.alert-radio-label.sc-ion-alert-md,
.alert-radio-label.sc-ion-alert-ios {
  white-space: normal !important;
}

.alert-tappable.alert-radio {
  height: auto;
  contain: content;
}

[hidden] {
  display: none !important;
}

ion-content {
  --background: var(--gd-comp-color-bg-2);

  ion-card[gdx-content] {
    // to make .action-top could sticky
    background: var(--gd-comp-color-bg-2);
    padding: 16px;
    box-shadow: none;
    border-radius: var(--gd-comp-border-radius) var(--gd-comp-border-radius) 0 0 !important;
    overflow: unset !important; // attd list detail
  }

  ion-card {
    border-radius: var(--gd-comp-border-radius);
  }
}

ion-searchbar {
  --ion-background-color: var(--gd-comp-color-bg-2);

  ion-icon {
    color: var(--ion-color-medium) !important;
  }
}

.field-box-datetime {
  @include shared-var.border-setting(1px solid var(--gd-comp-border-color-1), var(--gd-comp-border-radius));

  &.padding-fbd {
    padding: 2px 10px 2px 2px;
  }
}

h2.line-trough {
  &.no-margin {
    margin: 0 !important;
  }

  position: relative;
  z-index: 1;
  color: var(--ion-color-text-2) !important;
  margin-top: 24px;
  margin-bottom: 16px;
  text-transform: uppercase;
  &:before {
    border-top: 1px solid var(--gd-comp-border-color-1);
    content: '';
    margin: 0 auto;
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    bottom: 0;
    width: 95%;
    z-index: -1;
  }

  span {
    background: var(--gd-comp-color-bg-2);
    padding-right: 8px;
  }
}
