/* #region: ===== Mediascreen ================ */

// pakai min-width
$primary-mobile-width: 480px;
$primary-tablet-width: 768px; // 1 kolom
$primary-desktop-width: 1280px; // 3 kolom

$secondary-iphone-width: 320px; // [homepage] favorite menu 4 icon iphone 5s
$secondary-mobile-width: 360px; // [homepage] favorite menu 4 icon iphone 5s
$secondary-tablet-width: 680px; //  [gd-upload2-multiple, sf-upload2, sf-upload-image]
$secondary-desktop-width: 1024px; // [homepage] 2 kolom konten
$secondary-sidemenu-collapse: 1420px; // [AppMenuPage]sidemenu collapse

$terniary-desktop-width: 1366px; // login-demo

/* #endregion: == Mediascreen ================ */

// MIXIN //

@mixin position-setting($position, $top: null, $right: null, $bottom: null, $left: null) {
  position: $position;
  @if $top {
    top: $top;
  }
  @if $left {
    left: $left;
  }
  @if $right {
    right: $right;
  }
  @if $bottom {
    bottom: $bottom;
  }
}

@mixin shape-box($width: unset, $height: unset, $radius: 0) {
  width: $width;
  height: $height;
  border-radius: $radius;
}

@mixin font-setting($size, $color: unset, $weight: normal) {
  font-size: $size;
  color: $color;
  font-weight: $weight;
}
@mixin border-setting($size, $radius: 0) {
  border: $size;
  border-radius: $radius;
}

@mixin flex($dir: null, $content: null, $align: null, $wrap: null) {
  display: flex;
  @if $dir {
    flex-direction: $dir;
  }
  @if $content {
    justify-content: $content;
  }
  @if $align {
    align-items: $align;
  }
  @if $wrap {
    flex-wrap: $wrap;
  }
}

@mixin line-clamp($size) {
  display: -webkit-box !important;
  -webkit-box-orient: vertical;
  overflow: hidden;
  white-space: normal;
  @if $size > 0 {
    -webkit-line-clamp: $size;
  }
}
